const dogbreeds = [
  "affenpinscher",
  "african",
  "airedale",
  "akita",
  "appenzeller",
  "australian",
  "basenji",
  "beagle",
  "bluetick",
  "borzoi",
  "bouvier",
  "boxer",
  "brabancon",
  "briard",
  "buhund",
  "bulldog",
  "bullterrier",
  "cattledog",
  "chihuahua",
  "chow",
  "clumber",
  "cockapoo",
  "collie",
  "coonhound",
  "corgi",
  "cotondetulear",
  "dachshund",
  "dalmatian",
  "dane",
  "deerhound",
  "dhole",
  "dingo",
  "doberman",
  "elkhound",
  "entlebucher",
  "eskimo",
  "finnish",
  "frise",
  "germanshepherd",
  "greyhound",
  "groenendael",
  "havanese",
  "hound",
  "husky",
  "keeshond",
  "kelpie",
  "komondor",
  "kuvasz",
  "labradoodle",
  "labrador",
  "leonberg",
  "lhasa",
  "malamute",
  "malinois",
  "maltese",
  "mastiff",
  "mexicanhairless",
  "mix",
  "mountain",
  "newfoundland",
  "otterhound",
  "ovcharka",
  "papillon",
  "pekinese",
  "pembroke",
  "pinscher",
  "pitbull",
  "pointer",
  "pomeranian",
  "poodle",
  "pug",
  "puggle",
  "pyrenees",
  "redbone",
  "retriever",
  "ridgeback",
  "rottweiler",
  "saluki",
  "samoyed",
  "schipperke",
  "schnauzer",
  "segugio",
  "setter",
  "sharpei",
  "sheepdog",
  "shiba",
  "shihtzu",
  "spaniel",
  "spitz",
  "springer",
  "stbernard",
  "terrier",
  "tervuren",
  "vizsla",
  "waterdog",
  "weimaraner",
  "whippet",
  "wolfhound",
];
const dognames = [
  "Ace",
  "Addie",
  "Adele",
  "Annie",
  "Apollo",
  "Aspen",
  "Bailey",
  "Beamer",
  "Bear",
  "Belle",
  "Bella",
  "Birdie",
  "Bling",
  "Blue",
  "Bogey",
  "Body",
  "Boomer",
  "Bowen",
  "Breeze",
  "Brie",
  "Brody",
  "Buzz",
  "Callaway",
  "Casey",
  "Cash",
  "Catcher",
  "Chaos",
  "Chase",
  "Chili",
  "CiCi",
  "Cody",
  "Cole",
  "Comet",
  "Cooper",
  "Cruise",
  "Crush",
  "Daisy",
  "Dare",
  "Dash",
  "Dawson",
  "Dazzle",
  "Demi",
  "Denali",
  "Diva",
  "Dixie",
  "Echo",
  "Eli",
  "Ellie",
  "Emmy",
  "Evie",
  "Finn",
  "Flash",
  "Frankie",
  "Frisco",
  "Gator",
  "Georgia",
  "Ginger",
  "Grace",
  "Haley",
  "Happy",
  "Harley",
  "Hattie",
  "Hope",
  "Hunter",
  "Indy",
  "Jack",
  "Jamie",
  "Jax",
  "Jazz",
  "Jenna",
  "Jersey",
  "Jet",
  "Jinx",
  "JoJo",
  "Josie",
  "Joy",
  "Juno",
  "Karma",
  "Kenzi",
  "Kiva",
  "Kona",
  "Kyra",
  "Lacie",
  "Lark",
  "Laser",
  "Latte",
  "Levi",
  "Lilly",
  "Linx",
  "Logan",
  "Lucy",
  "Luke",
  "Max",
  "Mia",
  "Mojo",
  "Molly",
  "Murphy",
  "Nike",
  "Nova",
  "Obie",
  "Ollie",
  "Peach",
  "Penny",
  "Pepper",
  "Piper",
  "Prada",
  "Ranger",
  "Raven",
  "Reggie",
  "Remington",
  "Riley",
  "Ripley",
  "Riot",
  "River",
  "Roxie",
  "Ruby",
  "Rumor",
  "Salsa",
  "Scarlett",
  "Scout",
  "Shadow",
  "Shiloh",
  "Skye",
  "Slater",
  "Sophie",
  "Spark",
  "Spencer",
  "Spirit",
  "Spring",
  "Star",
  "Storm",
  "Strider",
  "Summer",
  "Tally",
  "Tango",
  "Tank",
  "Taylor",
  "Tease",
  "Tessa",
  "Token",  
  "Tori",
  "Tripp",
  "Trooper",
  "Tucker",
  "Tux",
  "Whip",
  "Wyatt",
  "Zeke",
  "Zip",
];
async function  getImage(breed) {
  const response = await fetch("https://dog.ceo/api/breed/" + breed + "/images/random");
  const json = await response.json();
  return await json.message;
}
async function  getOwner() {
  const response = await fetch("https://randommer.io/Name/?type=fullname&number=1&X-Requested-With=XMLHttpRequest", { method: "POST" });
  const json = await response.json();

  let owner = {
    name: json[0],
    age: Math.floor(Math.random() * (80 - 18 + 1)) + 18
  };
  return owner;
}
async function  getMiles() {
  // Generate a random number between 0 and 10 with decimals
  var randomNumber = Math.random() * 6;
  console.log(randomNumber); // Output a random number between 0 and 10 (exclusive)

  // If you want to limit the number of decimal places, you can use toFixed() method
  var decimalPlaces = 2;
  var roundedRandomNumber = randomNumber.toFixed(decimalPlaces);
  console.log(roundedRandomNumber); // Output a random number with 2 decimal places
  return roundedRandomNumber;
}
async function  getAge(int) {
  return Math.floor(Math.random() * int) + 1;
}
async function  getName() {
  return dognames[Math.floor(Math.random() * dognames.length)];
}
async function  getBreed() {
  return dogbreeds[Math.floor(Math.random() * dogbreeds.length)];
}
async function  getGender() {
  let math =  Math.floor(Math.random() * 13) + 1;
  return (math == 1 ? "Male" :"Female")
}
export { dogbreeds, dognames,getBreed,getGender,getName,getAge,getImage,getMiles,getOwner};
